@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  background-color: $clr-light-grey;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include vp--740 {
    height: 70vh;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: size-rem(440px);

  @include vp--740 {
    width: size-rem(302px, true);
    margin-top: size-rem(100px, true);
  }

  &__title {
    font-family: $main-font-family;
    font-size: size-rem(85px);
    font-style: normal;
    font-weight: 350;
    line-height: 95%;
    letter-spacing: size-rem(-1.7px);
    color: $clr-dark-black;
    margin-bottom: size-rem(28px);

    @include vp--740 {
      font-family: $main-font-family;
      font-size: size-rem(60px, true);
      font-style: normal;
      font-weight: 350;
      line-height: 95%;
      letter-spacing: size-rem(-1.2px, true);
      margin-bottom: size-rem(24px, true);
    }
  }
  &__description {
    font-family: $main-font-family;
    font-size: size-rem(18px);
    font-style: normal;
    font-weight: 350;
    line-height: 140%;
    color: $clr-dark-black;
    margin-bottom: size-rem(50px);
    text-align: center;

    @include vp--740 {
      font-size: size-rem(16px, true);
      font-style: normal;
      font-weight: 350;
      line-height: 120%;
      margin-bottom: size-rem(36px, true);
    }
  }
  &__reloadButton {
    color: $clr-dark-black;
    font-family: $main-font-family;
    font-size: size-rem(16px);
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    text-transform: uppercase;
    padding: size-rem(27px) size-rem(30px);
    border-radius: size-rem(100px);
    border: 1px solid $clr-grey;
    cursor: pointer;
    background-color: transparent;
    transition: all ease 0.13s;

    &:hover {
      background-color: $clr-dark-black;
      border: 1px solid $clr-dark-black;
      color: $clr-white;
      transition: all ease 0.13s;
    }

    &:active {
      background-color: $clr-dark-grey;
      border: 1px solid $clr-dark-grey;
      color: $clr-white;
      transition: all ease 0.13s;
    }

    @include vp--740 {
      padding: size-rem(16px, true) size-rem(24px, true);
      font-size: size-rem(16px, true);
      font-style: normal;
      font-weight: 350;
      line-height: 120%;
    }
  }
}
