@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.visualBottomLinks {
  border-top: 1px solid $clr-grey;
  padding-top: size-rem(28px);
  padding-bottom: size-rem(28px);
  justify-content: space-between;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: $clr-black;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: size-rem(40px);
  width: size-rem(440px);
}

.navBox {
  display: flex;
  width: 100%;
  justify-content: end;
  height: size-rem(50px);
}
