@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.sliderHand {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 15px;

  &__hand {
    display: block;
    position: relative;
    z-index: 3;
    width: auto;

    @media (min-width: 1023px) and (max-width: 1370px) and (orientation: landscape) {
      transform: translateY(0px);
      height: size-rem(70px);
      z-index: 3;
      width: size-rem(80px);
    }

    @media (min-width: 1023px) and (max-width: 1370px) and (orientation: portrait) {
      transform: translateY(0px);
      height: size-rem(70px);
      z-index: 3;
    }

    @media (min-device-width: 440px) and (max-device-width: 1023px) {
      height: size-rem(30px, true);
    }

    @media (min-device-width: 220px) and (max-device-width: 440px) {
      height: size-rem(60px, true);
    }
  }
}

.__right {
  justify-content: flex-end;
}

.__left {
  justify-content: flex-start;
}

.sliderHandWrapper {
  overflow: hidden;
  max-width: size-rem(1500px);
  margin: 0 size-rem(45px);

  @media (min-device-width: 1023px) and (max-width: 1270px) {
    max-width: 100%;
    margin-left: size-rem(35px);
    margin-right: size-rem(35px);
  }

  @media (min-device-width: 440px) and (max-width: 1023px) {
    margin-left: size-rem(24px, true);
    margin-right: size-rem(24px, true);
  }

  @media (min-device-width: 220px) and (max-width: 440px) {
    max-width: 100%;
    margin-left: size-rem(16px, true);
    margin-right: size-rem(16px, true);
  }

  &_visible {
    overflow: visible;
  }
}
