@import '../../../assets/scss/fonts.scss';
@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.visualInfo {
  margin-top: size-rem(12px);
  margin-left: size-rem(60px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(14px);
  line-height: 140%;
  color: $clr-black;
  width: 100%;

  @include vp--740 {
    margin-top: size-rem(8px, true);
    margin-left: 0;
    font-size: size-rem(12px, true);
  }

  &__text {
    text-wrap: nowrap !important;
  }

  &__dot {
    background-color: $clr-grey;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 size-rem(10px);

    @include vp--740 {
      width: size-rem(4px, true);
      height: size-rem(4px, true);
      margin: 0 size-rem(10px, true);
    }
  }
}
