@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  padding: size-rem(50px) 5% 0;
}

.views {
  display: flex;
  gap: size-rem(8px);
}

.view {
  padding: size-rem(16px) size-rem(23px);
  border-radius: size-rem(50px);
  border: 1px solid transparent;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(13px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  cursor: pointer;
  color: $clr-black;

  &:hover {
    border: 1px solid $clr-light-grey;
  }

  &__active {
    padding: size-rem(16px) size-rem(23px);
    border-radius: size-rem(50px);
    border: 1px solid $clr-grey;
    background-color: transparent;
    font-family: $main-font-family;
    font-size: size-rem(13px);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    cursor: pointer;
    color: $clr-black;
  }
}

.parkingFilters {
  display: grid;
  grid-template-columns: size-rem(152px) size-rem(180px) size-rem(200px) size-rem(200px) size-rem(200px) 1fr;
  margin-top: size-rem(40px);
  padding: 0 size-rem(30px);
}

.filterButton {
  text-align: left;
  border: none;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & div {
      transform: translateY(-1px);
      background-color: $clr-green;
      background-color: transparent;
      border-radius: 0;
      border-color: transparent transparent $clr-black;
      width: size-rem(8px);
      height: size-rem(8px);
      transition: 0.13s;

      border-left: size-rem(6px) solid transparent;
      border-right: size-rem(6px) solid transparent;
      border-bottom: size-rem(6px) solid $clr-green;
    }
  }

  &__dot {
    width: size-rem(6px);
    height: size-rem(6px);
    background-color: $clr-grey;
    border-radius: size-rem(6px);
    margin-left: size-rem(8px);
    // border-width: 0 0 size-rem(6px);
    transition: 0.13s;
    border-color: transparent transparent $clr-grey;
    border-style: solid;
  }
}

.activeFilterButton {
  text-align: left;
  border: none;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & div {
      background-color: $clr-green;
      background-color: transparent;
      border-radius: 0;
      border-color: transparent transparent $clr-black;
      width: size-rem(8px);
      height: size-rem(8px);
      transition: 0.13s;
      border-left: size-rem(6px) solid transparent;
      border-right: size-rem(6px) solid transparent;
      border-bottom: size-rem(6px) solid transparent;
      border-top: size-rem(6px) solid $clr-green;
      transform: translateY(2px);
    }
  }

  & div {
    transform: translateY(-1px);
    background-color: $clr-green;
    background-color: transparent;
    border-radius: 0;
    border-color: transparent transparent $clr-black;
    width: size-rem(8px);
    height: size-rem(8px);
    transition: 0.13s;

    border-left: size-rem(6px) solid transparent;
    border-right: size-rem(6px) solid transparent;
    border-bottom: size-rem(6px) solid $clr-green;
    border-bottom: size-rem(6px) solid $clr-dark-grey;
  }
}

.filterButtonDesc {
  text-align: left;
  border: none;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & div {
      background-color: $clr-green;
      background-color: transparent;
      border-radius: 0;
      border-color: transparent transparent $clr-black;
      width: size-rem(8px);
      height: size-rem(8px);
      transition: 0.13s;
      border-left: size-rem(6px) solid transparent;
      border-right: size-rem(6px) solid transparent;
      border-bottom: size-rem(6px) solid transparent;
      border-top: size-rem(6px) solid $clr-green;
      transform: translateY(2px);
    }
  }

  &__dot {
    width: size-rem(6px);
    height: size-rem(6px);
    background-color: $clr-grey;
    border-radius: size-rem(6px);
    margin-left: size-rem(8px);
    border-width: 0 0 size-rem(6px);
    transition: 0.13s;
    border-color: transparent transparent $clr-grey;
    border-style: solid;
  }
}

.activeFilterButtonDesc {
  text-align: left;
  border: none;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & div {
      background-color: $clr-green;
      background-color: transparent;
      border-radius: 0;
      border-color: transparent transparent $clr-green;
      width: size-rem(8px);
      height: size-rem(8px);
      transition: 0.13s;
      border-left: size-rem(6px) solid transparent;
      border-right: size-rem(6px) solid transparent;
      border-top: size-rem(6px) solid transparent;
      transform: translateY(-2px);
    }
  }

  & div {
    background-color: $clr-green;
    background-color: transparent;
    border-radius: 0;
    border-color: transparent transparent $clr-black;
    width: size-rem(8px);
    height: size-rem(8px);
    transition: 0.13s;
    border-left: size-rem(6px) solid transparent;
    border-right: size-rem(6px) solid transparent;
    border-bottom: size-rem(6px) solid transparent;
    border-top: size-rem(6px) solid $clr-dark-grey;
    transform: translateY(2px);
  }
}

.places {
  margin-top: size-rem(14px);
}
