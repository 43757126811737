@import './assets/scss/fonts.scss';
@import './assets/scss/variables.scss';
@import './assets/scss/functions.scss';
@import './assets/scss/mixins/custom-scrollbar.scss';
@import './assets/scss/mixins/responsive.scss';

* {
  box-sizing: content-box;
}

button {
  box-sizing: border-box;
}

body {
  margin: 0;
  overscroll-behavior-y: none;
  font-family: $primary-font-family;
  @include custom-scrollbar;

  @include vp--740 {
    overscroll-behavior-y: initial;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  will-change: transform;

  &--scroll {
    will-change: initial;
    display: initial;
    flex-direction: initial;
  }

  &--blocked {
    pointer-events: none !important;
  }

  @include vp--740 {
    will-change: initial;
    display: initial;
    flex-direction: initial;
  }
}

.target {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.swiper-pagination-fraction {
  color: $clr-white !important;
  font-size: size-rem(32px) !important;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 400;
}

.video-react-big-play-button-left {
  display: none !important;
}

.will-show {
  opacity: 0;
  transform: translateY(3.47222vw);
  transition:
    opacity 0.7s,
    transform 0.7s;

  @include vp--740 {
    transform: translateY(13.33333vw);
  }
}

.will-show-animate {
  opacity: 1;
  transform: none;

  @include vp--740 {
    transform: none;
  }
}

html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.noUi-target {
  background: $clr-grey !important;
  border: none !important;

  @include vp--740 {
    width: 90% !important;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 0%;
  }
}

.noUi-horizontal {
  height: size-rem(1px) !important;

  @include vp--740 {
    height: size-rem(1px, true) !important;
  }
}

.noUi-base {
  height: size-rem(2px) !important;

  @include vp--740 {
    height: size-rem(2px, true) !important;
  }
}

.noUi-connect {
  height: size-rem(2px) !important;
  background: $clr-green !important;
  overflow: initial;

  @include vp--740 {
    height: size-rem(2px, true) !important;
  }
}

.noUi-handle-lower {
  right: size-rem(-10px) !important;
  top: size-rem(-6px) !important;
  cursor: pointer !important;

  @include vp--740 {
    right: size-rem(-10px, true) !important;
    top: size-rem(-9px, true) !important;
  }
}

.noUi-handle-upper {
  top: size-rem(-6px) !important;
  right: size-rem(-14px) !important;
  cursor: pointer !important;

  @include vp--740 {
    right: size-rem(-10px, true) !important;
    top: size-rem(-9px, true) !important;
  }
}

.noUi-horizontal .noUi-handle {
  width: size-rem(14px) !important;
  height: size-rem(14px) !important;
  top: size-rem(-6px);

  @include vp--740 {
    width: size-rem(20px, true) !important;
    height: size-rem(20px, true) !important;
    top: size-rem(-6px);
  }
}

.noUi-handle {
  border: none !important;
  border: 1px solid $clr-green !important;
  border-radius: 50% !important;
  background: $clr-green !important;
  cursor: default;
  box-shadow: none !important;
  transition: all 0.2s;

  &:hover {
    background: $clr-white !important;
    border: size-rem(1px) solid $clr-green !important;
  }
}

.noUi-handle:before,
.noUi-handle:after {
  display: none !important;
}

.seventhScreenGeneralSwiperSlide {
  height: size-rem(803px) !important;
  @include vp--740 {
    height: initial !important;
  }
}

.seventhScreenGeneralSwiperSlide.__0 {
  background-image: url(./assets/images/plans_images/plan_4_plug.jpg);
  background-size: cover;
}
.seventhScreenGeneralSwiperSlide.__1 {
  background-image: url(./assets/images/plans_images/plan_3_plug.jpg);
  background-size: cover;
}
.seventhScreenGeneralSwiperSlide.__2 {
  background-image: url(./assets/images/plans_images/plan_1_plug.jpg);
  background-size: cover;
}
.seventhScreenGeneralSwiperSlide.__3 {
  background-image: url(./assets/images/plans_images/plan_5_plug.jpg);
  background-size: cover;
}
.seventhScreenGeneralSwiperSlide.__4 {
  background-image: url(./assets/images/plans_images/plan_2_plug.jpg);
  background-size: cover;
}

/* Стили для контента модалки с новостью */

.news_modal_content {
  .news_modal_img_wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    height: size-rem(456px);
    margin-top: size-rem(60px);

    @include vp--tablet {
      height: size-rem(600px, false, false, true);
      margin-top: size-rem(60px, false, false, true);
    }

    @include vp--740 {
      height: size-rem(284px, true);
      margin-top: size-rem(40px, true);
    }
  }

  .news_modal_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .news_modal_link {
    display: inline-block;
    border: none;
    background: none;
    color: inherit;
    font-family: $main-font-family !important;
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-top: size-rem(50px);

    @include vp--tablet {
      margin-top: size-rem(60px, false, false, true);
    }

    @include vp--740 {
      margin-top: size-rem(60px, true);
    }
  }

  a {
    border: 1px solid $clr-grey;
    border-radius: 100px;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    text-decoration: none;
    color: $clr-dark-black;
    padding: 10px;
    padding-right: 20px;

    display: flex;
    align-items: center !important;

    span {
      display: flex;
      align-items: center !important;

      &:nth-child(1) {
        display: inline-block;
        width: 80px;
        height: 53px;
        margin: 0 14px 0 0;
        border-radius: 100px;
        background-image: url('./assets/images/icon_images/terms_btn_image.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &:hover {
      background: $clr-dark-black;
      color: $clr-white;
      border-color: $clr-dark-black;
    }
  }

  .news_modal_comment {
    background: #f8f8f8;
    padding: size-rem(40px);
    margin-top: size-rem(60px);

    p {
      margin: 0 0 4px 0 !important;
      color: inherit !important;
      font-family: $main-font-family !important;
    }

    @include vp--tablet {
      padding: size-rem(40px, false, false, true);
      margin-top: size-rem(60px, false, false, true);
    }

    @include vp--740 {
      padding: size-rem(30px, true) size-rem(24px, true);
      margin-top: size-rem(40px, true);
    }

    & > div {
      display: flex;
    }

    &__avatar-wrapper {
      position: relative;
      overflow: hidden;
      display: block;
      width: size-rem(63px);
      height: size-rem(63px);
      border-radius: 100%;
      margin-right: 16px;

      @include vp--tablet {
        width: size-rem(63px, false, false, true);
        height: size-rem(63px, false, false, true);
      }

      @include vp--740 {
        width: size-rem(63px, true);
        height: size-rem(63px, true);
      }
    }

    &__avatar {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__name {
      font-weight: 300 !important;
      font-size: size-rem(32px) !important;
      line-height: 110% !important;
      margin: none !important;

      @include vp--tablet {
        font-size: size-rem(32px, false, false, true) !important;
      }

      @include vp--740 {
        font-size: size-rem(32px, true) !important;
      }
    }

    &__prof {
      font-size: size-rem(13px) !important;
      line-height: 120% !important;
      color: #1c1d1f !important;
      opacity: 0.5 !important;
      margin-top: size-rem(4px) !important;

      @include vp--tablet {
        font-size: size-rem(14px, false, false, true) !important;
        margin-top: size-rem(4px, false, false, true) !important;
      }

      @include vp--740 {
        font-size: size-rem(14px, true) !important;
        margin-top: size-rem(4px, true) !important;
      }
    }

    cite {
      @include vp--tablet {
        margin-top: size-rem(30px, false, false, true);
      }

      @include vp--740 {
        margin-top: size-rem(30px, true);
      }
    }
  }

  h1 {
    font-size: size-rem(62px);
    line-height: 110%;
    font-style: normal;
    font-weight: 350;
    /* or 68px */

    letter-spacing: -0.02em;
    margin-top: size-rem(60px);

    @include vp--tablet {
      margin-top: size-rem(60px, false, false, true);
      font-size: size-rem(62px, false, false, true);
    }

    @include vp--740 {
      font-size: size-rem(32px, true);
      margin-top: size-rem(30px, true);
    }
  }

  h2 {
    font-weight: 350;
    font-size: size-rem(40px);
    line-height: 110%;
    margin-top: size-rem(60px);
    /* identical to box height, or 44px */

    letter-spacing: -0.02em;

    @include vp--tablet {
      font-size: size-rem(40px, false, false, true);
      margin-top: size-rem(40px, false, false, true);
    }

    @include vp--740 {
      font-size: size-rem(20px, true);
      margin-top: size-rem(40px, true);
    }
  }

  h3 {
    font-weight: 350;
    font-size: size-rem(32px);
    line-height: 110%;
    margin-top: size-rem(60px);

    @include vp--tablet {
      font-size: size-rem(32px, false, false, true);
      margin-top: size-rem(40px, false, false, true);
    }

    @include vp--740 {
      font-size: size-rem(20px, true);
      margin-top: size-rem(40px, true);
    }
  }

  blockquote,
  .news_modal_content__inner p {
    margin: size-rem(60px) 0 0 0;
    font-size: size-rem(16px);
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #979797;
    font-family: $primary-font-family;

    @include vp--tablet {
      font-size: size-rem(16px, false, false, true);
    }

    @include vp--740 {
      font-size: size-rem(16px, true);
      margin-top: size-rem(40px, true);
    }
  }

  .news_modal_content__inner p a {
    display: inline-block;
    padding: size-rem(16px) size-rem(20px);
    font-size: size-rem(14px);
    font-family: $primary-font-family;

    &:active {
      background-color: $clr-darker-grey;
      border: 1px solid $clr-darker-grey;
    }

    @include vp--tablet {
      padding: size-rem(16px, false, false, true) size-rem(20px, false, false, true);
      font-size: size-rem(14px, false, false, true);
    }

    @include vp--740 {
      padding: size-rem(16px, true) size-rem(20px, true);
      font-size: size-rem(14px, true);
    }
  }

  figure {
    margin: size-rem(60px) 0 0 0;
    background: #e1e1e1;
    height: 1px;

    @include vp--tablet {
      margin: size-rem(60px, false, false, true) 0 0 0;
    }

    @include vp--740 {
      margin: size-rem(40px, true) 0 0 0;
    }
  }

  cite {
    display: block;
    margin: size-rem(20px) 0 0 0;
    font-size: size-rem(14px);
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #979797;
    font-family: $primary-font-family;
    font-style: normal;

    @include vp--tablet {
      font-size: size-rem(16px, false, false, true);
    }

    @include vp--740 {
      margin: size-rem(10px, true) 0 0 0;
      font-size: size-rem(14px, true);
    }
  }

  section {
    margin: size-rem(60px) 0 0 0;

    @include vp--tablet {
      margin: size-rem(60px, false, false, true) 0 0 0;
    }

    @include vp--740 {
      margin: size-rem(40px, true) 0 0 0;
    }

    cite {
      @include vp--740 {
        margin: size-rem(20px, true) 0 0 0;
      }
    }
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    margin: size-rem(20px) 0 0 0;

    @include vp--tablet {
      margin: size-rem(30px, false, false, true) 0 0 0;
    }

    @include vp--740 {
      margin: size-rem(20px, true) 0 0 0;
    }
  }

  ol li {
    counter-increment: my-awesome-counter;
    position: relative;
    padding-left: size-rem(20px);
    min-height: 40px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: size-rem(16px);
    letter-spacing: -0.02em;
    color: #979797;

    @include vp--tablet {
      padding-left: size-rem(20px, false, false, true);

      font-size: size-rem(16px, false, false, true);
    }

    @include vp--740 {
      padding-left: size-rem(20px, true);

      font-size: size-rem(16px, true);
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  ol li::before {
    content: counter(my-awesome-counter);
    color: #1c1d1f;
    position: absolute;
    --size: 40px;
    left: calc(-1 * var(--size));
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    border-radius: 50%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  ul {
    margin: size-rem(40px) 0 0 0;
    padding: 0;
    font-family: $primary-font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #979797;

    @include vp--tablet {
      margin: size-rem(40px, false, false, true) 0 0 0;
    }

    @include vp--740 {
      margin: size-rem(40px, true) 0 0 0;
    }
  }

  ul li {
    margin-top: 12px;
    list-style-type: none;
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-top: 0;
    }

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 6px;
      background: #979797;
      border-radius: 50%;
      margin-right: 16px;
      position: relative;
      bottom: -1px;
    }
  }

  .keen-slider {
    margin-top: size-rem(60px);

    position: relative;

    @include vp--tablet {
      margin-top: size-rem(60px, false, false, true);
    }

    @include vp--740 {
      margin-top: size-rem(40px, true);
    }
  }

  .keen-slider__slide {
    height: size-rem(456px);

    span {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
      display: block;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include vp--tablet {
      height: size-rem(600px, false, false, true);
    }

    @include vp--740 {
      height: size-rem(284px, true);
    }
  }

  .navigation-wrapper {
    position: relative;
  }

  .arrow {
    border: 1px solid #ffffff;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
      background-color: #ffffff;

      @include vp--740 {
        background-color: transparent;
      }
    }

    @include vp--740 {
      width: 49px;
      height: 49px;
    }
  }

  .arrow--left,
  .arrow--right {
    position: absolute;
    bottom: 40px;
    background-position: center;
    background-repeat: no-repeat;

    @include vp--740 {
      background-size: 30%;
      bottom: 20px;
    }

    &:active {
      background-color: #ffffff;
    }
  }

  .arrow--left {
    right: 136px;
    background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M0.468674 8.70711C0.0781498 8.31658 0.0781498 7.68342 0.468674 7.29289L6.83264 0.928933C7.22316 0.538409 7.85632 0.538409 8.24685 0.928933C8.63737 1.31946 8.63737 1.95262 8.24685 2.34315L2.58999 8L8.24685 13.6569C8.63737 14.0474 8.63737 14.6805 8.24685 15.0711C7.85633 15.4616 7.22316 15.4616 6.83264 15.0711L0.468674 8.70711ZM17.5 9L1.17578 9L1.17578 7L17.5 7L17.5 9Z" /></svg>');

    &:hover {
      background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="1C1C1C" xmlns="http://www.w3.org/2000/svg"><path d="M0.468674 8.70711C0.0781498 8.31658 0.0781498 7.68342 0.468674 7.29289L6.83264 0.928933C7.22316 0.538409 7.85632 0.538409 8.24685 0.928933C8.63737 1.31946 8.63737 1.95262 8.24685 2.34315L2.58999 8L8.24685 13.6569C8.63737 14.0474 8.63737 14.6805 8.24685 15.0711C7.85633 15.4616 7.22316 15.4616 6.83264 15.0711L0.468674 8.70711ZM17.5 9L1.17578 9L1.17578 7L17.5 7L17.5 9Z" /></svg>');

      @include vp--740 {
        background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M0.468674 8.70711C0.0781498 8.31658 0.0781498 7.68342 0.468674 7.29289L6.83264 0.928933C7.22316 0.538409 7.85632 0.538409 8.24685 0.928933C8.63737 1.31946 8.63737 1.95262 8.24685 2.34315L2.58999 8L8.24685 13.6569C8.63737 14.0474 8.63737 14.6805 8.24685 15.0711C7.85633 15.4616 7.22316 15.4616 6.83264 15.0711L0.468674 8.70711ZM17.5 9L1.17578 9L1.17578 7L17.5 7L17.5 9Z" /></svg>');
      }
    }

    @include vp--740 {
      right: 77px;
    }
  }

  .arrow--right {
    right: 40px;
    background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M17.5313 7.29289C17.9219 7.68342 17.9219 8.31658 17.5313 8.70711L11.1674 15.0711C10.7768 15.4616 10.1437 15.4616 9.75315 15.0711C9.36263 14.6805 9.36263 14.0474 9.75315 13.6569L15.41 8L9.75315 2.34315C9.36263 1.95262 9.36263 1.31946 9.75315 0.928933C10.1437 0.538409 10.7768 0.538409 11.1674 0.928933L17.5313 7.29289ZM0.5 7L16.8242 7L16.8242 9L0.5 9L0.5 7Z" /></svg>');

    &:hover {
      background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="1c1c1c" xmlns="http://www.w3.org/2000/svg"><path d="M17.5313 7.29289C17.9219 7.68342 17.9219 8.31658 17.5313 8.70711L11.1674 15.0711C10.7768 15.4616 10.1437 15.4616 9.75315 15.0711C9.36263 14.6805 9.36263 14.0474 9.75315 13.6569L15.41 8L9.75315 2.34315C9.36263 1.95262 9.36263 1.31946 9.75315 0.928933C10.1437 0.538409 10.7768 0.538409 11.1674 0.928933L17.5313 7.29289ZM0.5 7L16.8242 7L16.8242 9L0.5 9L0.5 7Z" /></svg>');

      @include vp--740 {
        background-image: url('data:image/svg+xml, <svg width="18" height="16" viewBox="0 0 18 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M17.5313 7.29289C17.9219 7.68342 17.9219 8.31658 17.5313 8.70711L11.1674 15.0711C10.7768 15.4616 10.1437 15.4616 9.75315 15.0711C9.36263 14.6805 9.36263 14.0474 9.75315 13.6569L15.41 8L9.75315 2.34315C9.36263 1.95262 9.36263 1.31946 9.75315 0.928933C10.1437 0.538409 10.7768 0.538409 11.1674 0.928933L17.5313 7.29289ZM0.5 7L16.8242 7L16.8242 9L0.5 9L0.5 7Z" /></svg>');
      }
    }

    @include vp--740 {
      right: 20px;
    }
  }

  .arrow--disabled.arrow--left {
    opacity: 0.5;
    pointer-events: none;
  }

  .arrow--disabled.arrow--right {
    opacity: 0.5;
    pointer-events: none;
  }

  .nums {
    position: absolute;
    bottom: size-rem(40px);
    left: size-rem(40px);
    font-size: size-rem(32px);
    line-height: 110%;
    color: $clr-white;

    @include vp--tablet {
      font-size: size-rem(32px, false, false, true);
    }

    @include vp--740 {
      bottom: size-rem(20px, true);
      left: size-rem(20px, true);
      font-size: size-rem(20px, true);
    }
  }
}

/* Стили для контента модалки с новостью */

.video-react.video-react-fullscreen {
  max-height: 100vh !important;
}

.swiper-button-next {
  color: red;
}

// <стрелки и пагинация слайдера в архитектуре>

.swiper-pagination {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 140%;
  text-align: center;
  color: $clr-white;
  margin-bottom: 72px;
  letter-spacing: -0.2em;

  @include vp--740 {
    margin-bottom: 60px;
  }
}

// </стрелки и пагинация слайдера в архитектуре>

// Стили для поэтажек

.floor__reserve {
  opacity: 0;
  z-index: 10;
}

.floor__active {
  opacity: 0.4 !important;
  fill: #1c1c1c;
  fill: $clr-green !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

// Стили для свайпера галереи

.gallery_swiper {
  height: 100%;

  .swiper-wrapper {
    transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  }

  .swiper-slide {
    width: auto !important;
  }

  &__next,
  &__prev {
    position: relative !important;
    height: size-rem(50px) !important;
    width: size-rem(50px) !important;
    margin: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: #ffffff;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    svg {
      width: size-rem(15px);
      height: size-rem(12px);
    }
  }

  .react-transform-wrapper,
  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }
}

// Стили для свайпера модалки галереи

.gallery_swiper_modal {
  height: 100%;

  .swiper-wrapper {
    transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  }

  &__next,
  &__prev {
    position: relative !important;
    height: 50px !important;
    width: 50px !important;
    margin: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: #ffffff;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .react-transform-wrapper,
  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }
}

// Стили для свайпера галереи на мобилке

.gallery_swiper_mobile {
  height: 100%;

  &__next,
  &__prev {
    position: relative !important;
    height: 36px !important;
    width: 36px !important;
    margin: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: #ffffff;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .react-transform-wrapper,
  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }
}
