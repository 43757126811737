@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.svgBox {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  // z-index: 1;

  @include vp--740 {
    // width: 200%;
    scroll-snap-align: center;
  }
}

.svg {
  width: size-rem(1440px);
  top: 0;
  left: 0;
  height: size-rem(789px);
  position: relative;
  z-index: 2;

  @include vp--740 {
    // width: 200%;
    width: auto;
    height: size-rem(500px, true);
    scroll-snap-align: center;
  }
}

.image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @include vp--740 {
    // width: 200%;
    width: auto;
    height: size-rem(500px, true);
    // scroll-snap-align: center;
  }
}
