@import '../../../assets/scss/fonts.scss';
@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.visualFlatsInfo {
  position: relative;
  margin-top: size-rem(40px);
  margin-left: size-rem(60px);
  margin-right: size-rem(60px);

  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-black;

  @include vp--740 {
    width: 100%;
    font-size: size-rem(12px, true);
    margin-left: 0;
    margin-right: 0;
  }

  &__rooms {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    padding: size-rem(10px) size-rem(8px);
    position: relative;

    @include vp--740 {
      padding: size-rem(10px, true) size-rem(8px, true);
    }

    &__item {
      &:first-child {
        font-family: $main-font-family;
        font-style: normal;
        font-weight: 350;
        font-size: size-rem(16px);
        line-height: 120%;
        color: $clr-dark-grey;

        @include vp--740 {
          font-size: size-rem(12px, true);
        }
      }
      &:nth-child(2) {
        position: absolute;
        top: auto;
        left: size-rem(160px);

        @include vp--740 {
          left: size-rem(160px, true);
        }
      }
      &:nth-child(3) {
        position: absolute;
        top: auto;
        width: size-rem(90px);
        text-align: left;
        right: size-rem(0px);

        @include vp--740 {
          width: size-rem(72px, true);
          text-align: left;
          right: size-rem(0px, true);
        }
      }
    }
  }
}
