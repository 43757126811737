@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.queueBox {
  width: size-rem(980px, true);
  position: absolute;
  left: size-rem(-370px, true);
  top: size-rem(160px, true);
}

.rightBlock {
  position: absolute;
  background-color: $clr-medium-grey;
  width: 100%;
  min-height: size-rem(512px, true);
  padding-left: size-rem(20px, true);
  padding-right: size-rem(20px, true);
  border-radius: size-rem(32px, true) size-rem(32px, true) 0 0;

  &__tabs {
    position: absolute;
    top: 0;
    padding-top: size-rem(80px, true);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    width: 100%;
  }
}

.title {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(50px, true);
  line-height: 110%;
  letter-spacing: -0.02em;
  color: $clr-black;
  margin-top: size-rem(32px, true);
  margin-left: size-rem(60px, true);

  @include vp--740 {
    font-size: size-rem(40px, true);
    margin-top: size-rem(16px, true);
    margin-left: 0;
  }
}

.description {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(32px, true);
  line-height: 110%;
  color: $clr-black;

  margin-top: size-rem(20px, true);
  margin-left: size-rem(60px, true);

  @include vp--740 {
    font-size: size-rem(24px, true);
    margin-top: size-rem(16px, true);
    margin-left: 0;

    &__lock {
      color: $clr-dark-grey;
      // font-size: size-rem(24px);
      padding-right: size-rem(60px, true);
    }
  }
}

.listLink {
  @include vp--740 {
    font-family: $main-font-family;
    font-size: size-rem(16px, true);
    margin-left: 0;
    margin-top: size-rem(25px, true);
  }
}
.leftBlock {
  height: 100%;
}

.compassBox {
  position: absolute;
  bottom: size-rem(52px, true);
}

.mobTabStyle {
  background-color: transparent;
  border: 1px solid transparent;
  color: $clr-black;
  opacity: 1;
  z-index: 200;
}

.mobTabActiveStyle {
  border: 1px solid $clr-dark-grey;
  background-color: transparent;
  color: $clr-black;
  z-index: 200;
}

.mobTabBehindStyle {
  border: 1px solid transparent;
  background-color: transparent;
  color: $clr-black;
  z-index: 200;
}
