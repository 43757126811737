@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  padding: size-rem(100px) 5%;
  display: flex;
  justify-content: space-between;

  @include vp--740 {
    flex-direction: column;
    padding: size-rem(50px, true) 5% size-rem(85px, true);
  }
}

.characteristics {
  width: size-rem(825px);

  @include vp--740 {
    width: 100%;
  }
}

.characteristic {
  list-style-type: none;
  padding: size-rem(30px) 0;
  border-bottom: 1px solid $clr-grey-2;

  font-family: $main-font-family;
  font-size: size-rem(18px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    font-size: size-rem(16px, true);
    font-style: normal;
    font-weight: 350;
    line-height: 140%;
    width: 100%;
    padding: size-rem(18px, true) 0;
  }
}

.title {
  font-family: $main-font-family;
  font-size: size-rem(40px);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: size-rem(-0.8px);
  width: size-rem(365px);

  @include vp--740 {
    font-size: size-rem(32px, true);
    font-style: normal;
    font-weight: 350;
    line-height: 110%;
    width: 100%;
  }
}
