@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.image {
  width: 100%;
}

.breadCrumbs {
  position: absolute;
  left: 5%;
  top: size-rem(128px);

  @include vp--740 {
    top: size-rem(84px, true);
  }
}

.title {
  position: absolute;
  left: 5%;
  top: size-rem(210px);
  color: $clr-white;
  font-family: $main-font-family;
  font-size: size-rem(85px);
  font-style: normal;
  font-weight: 350;
  line-height: 95%;
  letter-spacing: -1.7px;

  @include vp--740 {
    top: size-rem(148px, true);

    font-size: size-rem(40px, true);
    font-style: normal;
    font-weight: 350;
    line-height: 95%;
    letter-spacing: size-rem(-0.8px, true);
  }
}
