@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: $clr-wild-sand;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  padding: 0 5%;
}

.title {
  color: $clr-black;
  font-size: size-rem(32px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  margin-top: size-rem(62px, true);
  font-family: $main-font-family;
}
.parkingTypes {
  display: flex;
  gap: size-rem(8px, true);
  margin-top: size-rem(30px, true);
}
.parkingTypeButton {
  padding: size-rem(12px, true) size-rem(16px, true);
  background-color: transparent;
  border: 1px solid $clr-grey-2;
  border-radius: size-rem(50px, true);
  cursor: pointer;
  transition: all 0.13s ease;
  height: size-rem(42px, true);
  color: $clr-dark-black;
  font-size: size-rem(14px, true);
  font-family: $main-font-family;

  &__active {
    padding: size-rem(12px, true) size-rem(16px, true);
    background-color: $clr-green;
    border: 1px solid $clr-green;
    border-radius: size-rem(50px, true);
    color: $clr-white;
    height: size-rem(42px, true);
    font-size: size-rem(14px, true);
    font-family: $main-font-family;
  }

  &:hover {
    border: 1px solid $clr-lightdark-grey;
    transition: all 0.13s ease;
  }
}

.slider {
  width: 100%;
  margin-top: size-rem(25px, true);
}

.note {
  font-family: $main-font-family;
  font-size: size-rem(14px, true);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px, true);
  color: $clr-lightdark-grey;
}
.range {
  display: flex;
  justify-content: space-between;
  padding: size-rem(13px, true) 0 size-rem(19px, true);

  & span {
    color: $clr-black;
    font-family: $main-font-family;
    font-size: size-rem(16px, true);
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    padding-left: size-rem(6px, true);
  }

  & div {
    font-family: $main-font-family;
    font-size: size-rem(14px, true);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: size-rem(-0.28px, true);
    color: $clr-dark-grey;
  }
}

.buttonsBox {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 5% size-rem(30px, true);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size-rem(10px, true);

  & button {
    font-family: $main-font-family;
    font-size: size-rem(16px, true);
  }
}

.acceptButton {
  font-size: size-rem(16px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  color: $clr-white;
  background-color: $clr-black;
  border: none;
  padding: size-rem(16px, true) size-rem(28px, true);
  border-radius: size-rem(50px, true);
}

.resetButton {
  font-size: size-rem(16px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  padding: size-rem(16px, true) size-rem(28px, true);
  border-radius: size-rem(50px, true);
  border: 1px solid $clr-grey;
  background-color: transparent;
  color: $clr-dark-black;
}

.close {
  position: absolute;
  top: size-rem(20px, true);
  right: size-rem(20px, true);
}
