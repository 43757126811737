@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/fonts.scss';
@import '../../assets/scss/mixins/custom-style.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.thirdScreen {
  padding: 0 5%;
  position: relative;
  padding-bottom: size-rem(60px);
}

.thirdScreenOpen {
  padding: 0 5%;
  position: relative;
  padding-bottom: size-rem(60px);
}

.textBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: size-rem(80px);
  height: size-rem(500px);
}

.header {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(85px);
  line-height: 90%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-dark-black;
  opacity: 0.9;
  margin-bottom: size-rem(50px);
}

.text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-dark-black;

  max-width: size-rem(601px);
}

.bgBox {
  position: relative;
  height: size-rem(520px);
}

.bg {
  width: 100%;
}

.bgDescription {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(32px);
  line-height: 110%;
  text-align: right;
  color: $clr-white;
  position: absolute;
  top: size-rem(40px);
  left: size-rem(40px);
}

.cardsSection {
  margin-top: size-rem(80px);
  height: size-rem(804px);
}

.subtitle {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(40px);
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-dark-black;
}

.cardBox {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 60px;
  margin-top: size-rem(80px);
}

.card {
  padding: size-rem(20px);
}

.icon {
  width: 100%;
}

.description {
  text-align: center;
  margin-top: size-rem(20px);
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(18px);
  line-height: 140%;
  color: $clr-dark-black;
}

.crosses {
  position: absolute;
  align-self: center;
  justify-self: center;
}

.cross1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.cross2 {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

.cross3 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.hiddenSection {
  margin-top: size-rem(42px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: size-rem(67px);
}

.hiddenCard {
  display: flex;
  border-bottom: size-rem(1px) solid $clr-grey;
  padding-bottom: size-rem(38px);
  padding-top: size-rem(38px);

  &:nth-last-child(5) {
    border-bottom: none;
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.hiddenNumber {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(19px);
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $clr-white;

  background-color: $clr-dark-black;
  min-width: size-rem(30px);
  height: size-rem(30px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hiddenDescription {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(32px);
  line-height: 110%;
  color: $clr-dark-black;
  margin-left: size-rem(20px);
}

.buttonBox {
  margin-top: size-rem(80px);
  width: 100%;
  display: flex;
  justify-content: center;
  height: size-rem(71px);
}
