@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  display: grid;
  grid-template-columns: size-rem(152px) size-rem(180px) size-rem(200px) size-rem(200px) size-rem(200px) 1fr;
  margin-top: size-rem(4px);
  background-color: $clr-medium-grey;
  padding: size-rem(64px) size-rem(30px);
  align-items: center;

  & span {
    text-wrap: nowrap;
    font-family: $main-font-family;
    font-size: size-rem(18px);
    font-style: normal;
    font-weight: 350;

    &:nth-child(2) {
      top: size-rem(-2px);
      position: relative;
    }
  }
}

.buttons {
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  display: flex;
  text-wrap: nowrap;
  gap: size-rem(16px);
  justify-content: flex-end;
}

.bookingButton {
  background-color: $clr-black;
  color: $clr-white;
  border: none;
  padding: size-rem(16px) size-rem(25px);
  border-radius: size-rem(50px);
  cursor: pointer;
  font-family: $main-font-family;
  font-size: size-rem(16px);

  &:hover {
    background-color: $clr-darker-grey;
    transition: all 0.13s ease-in-out;
  }

  &:active {
    background-color: $clr-middle-grey;
    transition: all 0.13s ease-in-out;
  }

  @include vp--740 {
    padding: size-rem(16px, true) 0;
    display: block;
    width: 100%;
    border-radius: size-rem(50px, true);
    margin-top: size-rem(30px, true);
    margin-bottom: size-rem(10px, true);
    font-size: size-rem(16px, true);
    height: size-rem(50px, true);

    &:hover {
      background-color: $clr-black;
      transition: all 0.13s ease-in-out;
    }
  }
}

.callButton {
  background-color: transparent;
  border: 1px solid $clr-grey;
  border-radius: size-rem(50px);
  padding: size-rem(16px) size-rem(25px);
  cursor: pointer;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  color: $clr-black;

  &:hover {
    background-color: $clr-black;
    transition: all 0.13s ease-in-out;
    border: 1px solid $clr-black;
    color: $clr-white;
  }

  &:active {
    background-color: $clr-darker-grey;
    transition: all 0.13s ease-in-out;
    border: 1px solid $clr-black;
    color: $clr-white;
  }

  @include vp--740 {
    padding: size-rem(16px, true) 0;
    display: block;
    width: 100%;
    border-radius: size-rem(50px, true);
    font-size: size-rem(16px, true);
    height: size-rem(50px, true);

    &:hover {
      background-color: transparent;
      transition: all 0.13s ease-in-out;
      color: $clr-white;
      color: $clr-black;
      border: 1px solid $clr-grey;
    }
  }
}

.price {
  font-family: $main-font-family;
  font-size: size-rem(32px) !important;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.heart {
  display: flex;
  align-items: center;
  justify-content: center;

  // margin-left: auto;
  width: size-rem(50px);
  height: size-rem(50px);
  border-radius: 50%;
  background: transparent;
  box-sizing: border-box;
  border: size-rem(1px) solid transparent;

  transition: all 0.2s ease;

  cursor: pointer;

  &:hover {
    box-sizing: border-box;
    border: size-rem(1px) solid #e1e1e1;
  }

  & svg {
    width: size-rem(16px);
    height: size-rem(14px);

    animation: scaleHeart 1s;

    @include vp--tablet2 {
      width: size-rem(13px, false, false, true);
      height: size-rem(13px, false, false, true);
    }

    @include vp--740 {
      width: size-rem(13.5px, true);
      height: size-rem(13.5px, true);
    }
  }

  @include vp--tablet2 {
    width: size-rem(30px, false, false, true);
    height: size-rem(30px, false, false, true);
  }

  @include vp--740 {
    width: size-rem(42px, true);
    height: size-rem(42px, true);

    &:hover {
      box-sizing: border-box;
      border: none;
    }
  }

  &__white {
    display: flex;
    align-items: center;
    justify-content: center;

    // margin-left: auto;
    width: size-rem(50px);
    height: size-rem(50px);
    border-radius: 50%;
    box-sizing: border-box;
    border: size-rem(1px) solid transparent;
    background: $clr-green;
    cursor: pointer;

    & svg {
      width: size-rem(16px);
      height: size-rem(14px);

      animation: unScaleHeart 1s;

      @include vp--740 {
        width: size-rem(13.5px, true);
        height: size-rem(13.5px, true);
      }
    }

    &:hover svg > path {
      transition: ease 0.4s;
      fill: $clr-white;
    }

    @include vp--tablet2 {
      width: size-rem(30px, false, false, true);
      height: size-rem(30px, false, false, true);
    }

    @include vp--740 {
      width: size-rem(42px, true);
      height: size-rem(42px, true);
    }
  }
}

.wrapperMob {
  background-color: $clr-medium-grey;
  padding: size-rem(20px, true);
}

.infoBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: size-rem(20px, true);
}

.dot {
  background-color: $clr-grey;
  width: size-rem(4px, true);
  height: size-rem(4px, true);
  border-radius: 50%;
  margin: 0 size-rem(8px, true);
}

.date {
  font-size: size-rem(14px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
  font-family: $main-font-family;
  color: $clr-lightdark-grey;
}

.infoSecondLine {
  display: flex;
  align-items: center;
  margin-top: size-rem(5px);
}

.number {
  font-size: size-rem(14px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
  font-family: $main-font-family;
}

.type {
  font-size: size-rem(14px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
  font-family: $main-font-family;
}

.priceMob {
  font-size: size-rem(32px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  font-family: $main-font-family;
  // padding-top: size-rem(20px, true);
}

.mapCard {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  width: size-rem(390px);
  height: size-rem(340px);
  background-color: $clr-white;
  padding: size-rem(30px) size-rem(30px) size-rem(40px) size-rem(30px);

  // & span {
  //   font-family: $main-font-family;
  //   font-size: size-rem(18px);
  //   font-style: normal;
  //   font-weight: 350;
  //   line-height: 140%;
  // }
}

.upSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  // background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  height: fit-content;
}
