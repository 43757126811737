@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.price {
  position: absolute;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(65px);
  line-height: 110%;
  color: $clr-black;
  bottom: size-rem(128px);
  left: size-rem(20px);

  @include vp--740 {
    font-size: size-rem(36px, true);
    position: fixed;
    bottom: size-rem(36px, true);
    z-index: 100;
    left: size-rem(20px, true);
  }
}

.priceDiscount {
  position: absolute;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(65px);
  color: $clr-black;
  bottom: size-rem(128px);
  left: size-rem(20px);

  @include vp--740 {
    position: fixed;
    font-size: size-rem(36px, true);
    bottom: size-rem(24px, true);
    z-index: 100;
    left: size-rem(20px, true);
  }

  &__discount {
    display: flex;
    gap: size-rem(12px);
    align-items: center;
    white-space: nowrap;

    &__text {
      color: $clr-dark-grey;
      font-size: size-rem(32px);
      text-decoration: line-through;
      line-height: 130%;
      font-family: $main-font-family;
      font-style: normal;
      font-weight: 350;

      @include vp--740 {
        font-size: size-rem(16px, true);
      }

      & + p {
        display: flex;
        justify-content: center;
        align-items: center;
        width: size-rem(30.11px);
        height: size-rem(30.84px);

        font-size: size-rem(20px);
        text-decoration: none;
        font-family: $main-font-family;
        font-style: normal;
        font-weight: 350;
        line-height: 110%;
        color: $clr-dark-grey;

        border: size-rem(1px) solid $clr-dark-grey;
        border-radius: 50%;

        & > span {
          font-family: system-ui;
        }
      }
    }
  }

  &__amount {
    font-family: $main-font-family;
    width: initial;
    margin-left: 0px;
    color: $clr-green;
    font-size: size-rem(60px);
    white-space: nowrap;
    margin-bottom: size-rem(24px);

    @include vp--740 {
      font-size: size-rem(36px, true);
    }
  }
}

.tooltip {
  top: size-rem(-90px);

  @include vp--740 {
    top: size-rem(0px, true);
  }
}
