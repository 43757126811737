@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: $primary-font-family;
  @include vp--740 {
    flex-wrap: wrap;
    row-gap: size-rem(8px, true);
  }
}

.item,
.link {
  text-decoration: none;
  display: block;
  position: relative;
  font-size: size-rem(14px);
  line-height: 100%;

  @include vp--tablet {
    font-size: size-rem(14px, false, false, true);
  }

  @include vp--740 {
    font-size: size-rem(14px, true);
  }
}

.link {
  transition: 0.15s;
}

.link:hover {
  text-decoration: underline !important;
  text-underline-offset: size-rem(5px);
}

.dotWrapper {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  padding-left: 13px;
  padding-right: 13px;
  flex-shrink: 0;

  @include vp--tablet {
    padding-left: size-rem(12px, false, false, true);
    padding-right: size-rem(12px, false, false, true);
  }

  @include vp--740 {
    padding-left: size-rem(12px, true);
    padding-right: size-rem(12px, true);
  }
}

.dot {
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 100px;

  @include vp--740 {
    width: 2px;
    height: 2px;
  }
}

.textComponent {
  list-style-type: none;
}
