@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.wrapper {
  position: relative;
}

.image {
  width: 100%;
}

.icon {
  position: absolute;
  bottom: size-rem(16px, true);
  right: size-rem(16px, true);
  width: size-rem(32px, true);
}

.modal {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 9999999 !important;

  &::before {
    content: '';
    background-color: black;
    opacity: 90%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }
}

.modalImage {
  width: 100%;
  position: relative;
  object-fit: cover;
}

.scrollDisabled {
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  -ms-touch-action: none;
}

.imgButton {
  border: none;
  outline: none;
}
