@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  height: size-rem(480px, true);
  transition: all 0.3s ease;
  z-index: 80;
}

.flatWrapper {
  position: fixed;
  left: 0;
  right: 0;
  height: size-rem(440px, true);
  transition: all 0.3s ease;
  z-index: 80;
}

.toggler {
  position: absolute;
  top: size-rem(12px, true);
  left: 50%;
  transform: translateX(-50%);
  width: size-rem(72px, true);
  height: size-rem(5px, true);
  border-radius: size-rem(10px, true);
  background-color: $clr-grey;
  opacity: 50%;
  z-index: 1000;
}

.children {
  position: relative;
  height: fit-content;
  height: 100%;
}

.sheetForRoot {
  bottom: size-rem(-370px, true);
}

.sheetForFloor {
  bottom: size-rem(-300px, true);
  bottom: size-rem(-358px, true);
}

.sheetForFlat {
  bottom: size-rem(-260px, true);
}

.openSheet {
  bottom: 0;
}

.openSheetForm {
  bottom: size-rem(60px, true);
}

.flatSheetOpen {
  bottom: size-rem(-140px, true);
  bottom: size-rem(0px, true);
}

.tagMob {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: size-rem(-48px, true);

  padding: size-rem(12px, true) size-rem(16px, true) size-rem(8px, true);
  border: none;
  border-radius: size-rem(32px, true);
  display: flex;
  align-items: center;
  z-index: 10000;
}
