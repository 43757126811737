@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.content {
  padding-top: size-rem(130px);

  @include vp--740 {
    padding-top: size-rem(72px, true);
  }
}

.bredCrumbs_block {
  padding: 0 5%;

  @include vp--740 {
    position: absolute;
    top: size-rem(83px, true);
    left: 0;
  }
}
