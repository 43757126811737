@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  margin-top: size-rem(40px);
  position: relative;

  @include vp--740 {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @include vp--740 {
    width: auto;
    height: size-rem(500px, true);
  }
}

.svgBox {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  @include vp--740 {
    scroll-snap-align: center;
  }
}

.svg {
  width: size-rem(1440px);
  top: 0;
  left: 0;
  height: size-rem(789px);
  position: relative;
  z-index: 2;

  @include vp--740 {
    width: auto;
    height: size-rem(500px, true);
    scroll-snap-align: center;
  }
}

.greenHover {
  fill: #0b3d00 !important;
}

.place {
  fill: transparent;
  &__big {
    fill: #c0e3c3;
    cursor: pointer;
  }
  &__small {
    fill: #8ab380;
    cursor: pointer;
  }
  &__pantry {
    fill: #d2e8f7;
    cursor: pointer;
  }
}

.mapCard {
  position: absolute;
  left: 0;
  top: 0;
  width: size-rem(390px);
  height: size-rem(340px);
  background-color: $clr-white;
  padding: size-rem(30px) size-rem(30px) size-rem(40px) size-rem(30px);
  z-index: 100;

  &__close {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: size-rem(-6px);
    left: size-rem(187px);
    transform: rotate(45deg);
    width: size-rem(16px);
    height: size-rem(16px);
    background-color: white;
    z-index: 99;
  }

  &__top {
    &::before {
      bottom: auto;
      top: size-rem(-8px);
      background-color: $clr-white;
    }
  }

  &__right {
    &::before {
      bottom: size-rem(160px);
      right: size-rem(-8px);
      left: auto;
      rotate: 90deg;
    }
  }

  &__left {
    &::before {
      left: size-rem(-8px);

      bottom: size-rem(161px);
      right: auto;
      top: auto;
      rotate: -90deg;
    }
  }
}

.upSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  transition: all 0.22s ease-in-out;
  rotate: 0deg;
  height: size-rem(24px);
  width: size-rem(24px);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    rotate: 90deg;
    transition: all 0.22s ease-in-out;
    cursor: pointer;

    & rect {
      fill: $clr-grey;
    }
  }

  @include vp--740 {
    height: size-rem(24px, true);
    width: size-rem(24px, true);
  }
}

.heart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: size-rem(50px);
  height: size-rem(50px);
  border-radius: 50%;
  background: transparent;
  box-sizing: border-box;
  border: size-rem(1px) solid transparent;

  transition: all 0.2s ease;

  cursor: pointer;

  &:hover {
    box-sizing: border-box;
    border: size-rem(1px) solid #e1e1e1;
  }

  & svg {
    width: size-rem(16px);
    height: size-rem(14px);

    animation: scaleHeart 1s;

    @include vp--tablet2 {
      width: size-rem(13px, false, false, true);
      height: size-rem(13px, false, false, true);
    }

    @include vp--740 {
      width: size-rem(13.5px, true);
      height: size-rem(13.5px, true);
    }
  }

  @include vp--tablet2 {
    width: size-rem(30px, false, false, true);
    height: size-rem(30px, false, false, true);
  }

  @include vp--740 {
    width: size-rem(42px, true);
    height: size-rem(42px, true);

    &:hover {
      box-sizing: border-box;
      border: none;
    }
  }

  &__white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: size-rem(50px);
    height: size-rem(50px);
    border-radius: 50%;
    box-sizing: border-box;
    border: size-rem(1px) solid transparent;
    background: $clr-green;
    cursor: pointer;

    & svg {
      width: size-rem(16px);
      height: size-rem(14px);

      animation: unScaleHeart 1s;

      @include vp--740 {
        width: size-rem(13.5px, true);
        height: size-rem(13.5px, true);
      }
    }

    &:hover svg > path {
      transition: ease 0.4s;
      fill: $clr-white;
    }

    @include vp--tablet2 {
      width: size-rem(30px, false, false, true);
      height: size-rem(30px, false, false, true);
    }

    @include vp--740 {
      width: size-rem(42px, true);
      height: size-rem(42px, true);
    }
  }
}

.type {
  font-family: $main-font-family;
  font-size: size-rem(32px);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: size-rem(-0.64px);
}

.description {
  font-family: $main-font-family;
  font-size: size-rem(18px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    font-size: size-rem(14px, true);
  }
}

.secondLine {
  display: flex;
  align-items: center;
  gap: size-rem(10px);
  margin-top: size-rem(20px);
}

.thirdLine {
  display: flex;
  align-items: center;
  gap: size-rem(11px);
  margin-top: size-rem(12px);

  @include vp--740 {
    gap: size-rem(8px, true);
    margin-top: size-rem(12px, true);
  }
}

.dot {
  width: size-rem(4px);
  height: size-rem(4px);
  background-color: $clr-grey;
  border-radius: 50%;

  @include vp--740 {
    width: size-rem(4px, true);
    height: size-rem(4px, true);
  }
}

.area {
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    font-size: size-rem(14px, true);
  }
}

.price {
  font-family: $main-font-family;
  font-size: size-rem(40px);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: size-rem(-0.8px);
  margin-top: size-rem(16px);

  @include vp--740 {
    margin-top: 0;
    font-size: size-rem(32px, true);
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: size-rem(30px);
  gap: size-rem(10px);

  & button {
    padding: size-rem(16px) 0;
    border-radius: size-rem(50px);
    cursor: pointer;

    @include vp--740 {
      border-radius: size-rem(50px, true);
      font-size: size-rem(16px, true);
      height: size-rem(50px, true);
    }
  }

  @include vp--740 {
    display: flex;
    flex-direction: column;
    margin-top: size-rem(20px, true);
    gap: size-rem(10px, true);
  }
}

.bookingButton {
  background-color: $clr-black;
  color: $clr-white;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  border: none;

  &:hover {
    background-color: $clr-darker-grey;
    transition: all 0.13s ease-in-out;
  }

  &:active {
    background-color: $clr-middle-grey;
    transition: all 0.13s ease-in-out;
  }

  @include vp--740 {
    &:hover {
      background-color: $clr-black;
      transition: all 0.13s ease-in-out;
    }
  }
}

.callButton {
  background-color: transparent;
  border: 1px solid $clr-grey;
  border-radius: size-rem(50px);
  padding: size-rem(16px) size-rem(25px);
  cursor: pointer;
  font-size: size-rem(16px);
  font-family: $main-font-family;
  color: $clr-darker-grey;

  &:hover {
    background-color: $clr-black;
    transition: all 0.13s ease-in-out;
    border: 1px solid $clr-black;
    color: $clr-white;
  }

  &:active {
    background-color: $clr-darker-grey;
    transition: all 0.13s ease-in-out;
    border: 1px solid $clr-black;
    color: $clr-white;
  }

  @include vp--740 {
    padding: size-rem(16px, true) 0;
    display: block;
    width: 100%;
    border-radius: size-rem(50px, true);

    &:hover {
      background-color: transparent;
      transition: all 0.13s ease-in-out;
      border: 1px solid $clr-grey;
      color: $clr-black;
    }
  }
}

.mobCard {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 800;
  background-color: $clr-white;
  width: size-rem(272px, true);
  height: size-rem(279px, true);

  padding: size-rem(15px, true) size-rem(15px, true) size-rem(20px, true);

  &::before {
    content: '';
    position: absolute;
    bottom: size-rem(-26px, true);
    left: size-rem(116px, true);
    border-width: size-rem(16px, true);
    border-style: solid;
    border-color: transparent transparent $clr-white transparent;
    rotate: 180deg;
  }

  &__top {
    &::before {
      bottom: auto;
      top: size-rem(-28px, true);
      left: size-rem(116px, true);
      background-color: transparent;
      border-width: size-rem(16px, true);
      rotate: 0deg;
    }
  }

  &__right {
    &::before {
      bottom: size-rem(128px, true);
      right: size-rem(-28px, true);
      left: auto;
      border-width: size-rem(16px, true);
      rotate: 90deg;
      top: auto;
    }
  }

  &__left {
    &::before {
      left: size-rem(-28px, true);
      bottom: size-rem(128px, true);
      right: auto;
      top: auto;
      rotate: -90deg;
    }
  }
}

.priceBox {
  margin-top: size-rem(10px, true);
  display: flex;
  align-items: center;
  gap: size-rem(12px, true);
}

.zoomButtons {
  display: flex;
  position: absolute;
  z-index: 10;
  right: size-rem(24px);
  top: size-rem(24px);
  gap: size-rem(16px);

  & button {
    background-color: $clr-white;
    border: none;
    border-radius: 50%;
    width: size-rem(50px);
    height: size-rem(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: $clr-black;
      transition: all 0.3s ease;

      & svg path {
        fill: $clr-white;
        transition: all 0.3s ease;
      }
    }

    &:active {
      background-color: $clr-middle-grey;
      transition: all 0.3s ease;
    }
  }
}
