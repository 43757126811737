@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.tab {
  box-sizing: border-box;
  position: relative;
  border: 1px solid $clr-medium-grey;
  border-radius: size-rem(48px);
  padding: size-rem(12px) size-rem(18px);
  display: inline-block;

  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-lightdark-grey;
  margin-right: size-rem(8px);
  transition: all 0.13s ease;

  background-color: $clr-medium-grey;

  cursor: auto;

  @include vp--740 {
    border-radius: size-rem(100px, true);
    padding: size-rem(8px, true) size-rem(14px, true);
    font-size: size-rem(14px, true);
    margin-right: size-rem(8px, true);

    &:last-child {
      margin-right: 0;
    }
  }

  &__active {
    box-sizing: border-box;
    position: relative;
    border: 1px solid $clr-lightdark-grey;
    border-radius: size-rem(48px);
    padding: size-rem(12px) size-rem(18px);
    display: inline-block;

    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(16px);
    line-height: 120%;
    color: $clr-black;
    margin-right: size-rem(8px);

    background-color: $clr-medium-grey;

    cursor: auto;

    @include vp--740 {
      border-radius: size-rem(100px, true);
      padding: size-rem(8px, true) size-rem(14px, true);
      font-size: size-rem(14px, true);
      margin-right: size-rem(8px, true);
      cursor: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__behind {
    box-sizing: border-box;
    position: relative;
    border: 1px solid $clr-medium-grey;
    border-radius: size-rem(48px);
    padding: size-rem(12px) size-rem(18px);
    display: inline-block;

    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(16px);
    line-height: 120%;

    color: $clr-black;
    margin-right: size-rem(8px);
    transition: all 0.13s ease;

    background-color: $clr-medium-grey;

    cursor: pointer;
    &:hover {
      border: 1px solid $clr-grey;
      cursor: pointer;
    }

    @include vp--740 {
      border-radius: size-rem(100px, true);
      padding: size-rem(8px, true) size-rem(14px, true);
      font-size: size-rem(14px, true);
      margin-right: size-rem(8px, true);
      cursor: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tabMob {
  box-sizing: border-box;
  position: relative;
  border-radius: size-rem(48px);
  padding: size-rem(12px) size-rem(18px);
  display: inline-block;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-black;
  margin-right: size-rem(8px);
  transition: all 0.13s ease;
  background-color: $clr-white;
  border: 1px solid $clr-white;

  cursor: auto;

  @include vp--740 {
    border-radius: size-rem(100px, true);
    padding: size-rem(8px, true) size-rem(14px, true);
    font-size: size-rem(14px, true);
    margin-right: size-rem(8px, true);

    &:last-child {
      margin-right: 0;
    }
  }

  &__active {
    box-sizing: border-box;
    position: relative;
    border: 1px solid $clr-grey;
    border-radius: size-rem(48px);
    padding: size-rem(12px) size-rem(18px);
    display: inline-block;

    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(16px);
    line-height: 120%;
    color: $clr-black;
    margin-right: size-rem(8px);

    background-color: $clr-white;

    cursor: auto;

    @include vp--740 {
      border-radius: size-rem(100px, true);
      padding: size-rem(8px, true) size-rem(14px, true);
      font-size: size-rem(14px, true);
      margin-right: size-rem(8px, true);
      cursor: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__behind {
    box-sizing: border-box;
    position: relative;
    border: 1px solid $clr-white;
    border-radius: size-rem(48px);
    padding: size-rem(12px) size-rem(18px);
    display: inline-block;

    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(16px);
    line-height: 120%;

    color: $clr-black;
    margin-right: size-rem(8px);
    transition: all 0.13s ease;

    background-color: $clr-white;

    cursor: auto;

    @include vp--740 {
      border-radius: size-rem(100px, true);
      padding: size-rem(8px, true) size-rem(14px, true);
      font-size: size-rem(14px, true);
      margin-right: size-rem(8px, true);
      cursor: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.activeWhiteMob {
  box-sizing: border-box;
  position: relative;
  border: 1px solid $clr-white;
  border-radius: size-rem(48px);
  padding: size-rem(12px) size-rem(18px);
  display: inline-block;

  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-white;
  margin-right: size-rem(8px);
  background: none;

  cursor: auto;

  @include vp--740 {
    border-radius: size-rem(100px, true);
    padding: size-rem(8px, true) size-rem(14px, true);
    font-size: size-rem(14px, true);
    margin-right: size-rem(8px, true);
    cursor: auto;

    &:last-child {
      margin-right: 0;
    }
  }
}

.defaultWhiteMob {
  box-sizing: border-box;
  position: relative;
  border-radius: size-rem(48px);
  padding: size-rem(12px) size-rem(18px);
  display: inline-block;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-white;
  margin-right: size-rem(8px);
  background: none;
  border: none;
  cursor: auto;

  @include vp--740 {
    border-radius: size-rem(100px, true);
    padding: size-rem(8px, true) size-rem(14px, true);
    font-size: size-rem(14px, true);
    margin-right: size-rem(8px, true);
    cursor: auto;
    border: none;
    &:last-child {
      margin-right: 0;
    }
  }
}
