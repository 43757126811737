@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.thirdScreen {
  width: 100%;
  padding: size-rem(60px, true) 0;
}

.textBox {
  padding: 0 5%;
}

.title {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(40px, true);
  line-height: 95%;
  letter-spacing: -0.02em;
  color: $clr-black;
  text-align: center;
}

.text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px, true);
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-black;

  margin-top: size-rem(24px, true);
}

.bgBox {
  position: relative;
  margin: size-rem(50px, true) 5% 0 5%;
}

.bg {
  width: 100%;
}

.bgDescription {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px, true);
  line-height: 110%;
  text-align: right;
  color: $clr-white;
  position: absolute;
  top: size-rem(16px, true);
  left: size-rem(16px, true);
}

.cardsSection {
  margin-top: size-rem(50px, true);
}

.subtitle {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(30px, true);
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $clr-black;
}

.cardBox {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: size-rem(12px, true);
  padding: 0 5%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  border: 1px solid $clr-grey;
  max-width: size-rem(272px, true);
  position: relative;
  padding: size-rem(20px, true);
}

.icon {
  max-width: size-rem(200px, true);
}

.description {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px, true);
  line-height: 120%;
  text-align: center;
  color: $clr-black;
}

.hiddenSection {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  margin-top: size-rem(40px, true);
}

.hiddenCard {
  border-bottom: size-rem(1px) solid $clr-grey;

  &:nth-last-child(1) {
    border-bottom: none;

    .hiddenDescription {
      padding-bottom: 0;
    }
  }

  &:nth-of-type(1) {
    .hiddenDescription {
      padding-top: 0;
    }
  }
}

.hiddenDescription {
  margin-left: size-rem(16px, true);
  padding: size-rem(24px, true) 0;

  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(20px, true);
  line-height: 130%;
  color: $clr-black;
}

.buttonBox {
  margin-top: size-rem(40px, true);
  width: 100%;
  display: flex;
  justify-content: center;
}
