@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/functions.scss';
@import '../../../../assets/scss/mixins/flexbox.scss';
@import '../../../../assets/scss/mixins/responsive.scss';
@import '../../../../assets/scss/mixins/custom-scrollbar.scss';

.backIcon {
  width: size-rem(21px);

  @include vp--740 {
    width: size-rem(21px, true);
  }
}

.backLink {
  border: 1px solid $clr-grey;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: size-rem(50px);
  height: size-rem(50px);
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border: 1px solid $clr-dark-grey;
  }

  @include vp--740 {
    background-color: $clr-white;
    left: size-rem(12px, true);
    border: none;
    width: size-rem(50px, true);
    height: size-rem(50px, true);
    margin-right: size-rem(10px, true);
    position: absolute;
    top: size-rem(-58px, true);
  }
}

.backLinkGrey {
  border: 1px solid $clr-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  width: size-rem(50px);
  height: size-rem(50px);
  border-radius: 50%;

  cursor: pointer;

  &:hover {
    border: 1px solid $clr-dark-grey;
  }

  @include vp--740 {
    position: relative;
    left: size-rem(12px, true);
    margin-bottom: size-rem(8px, true);
    border: none;
    width: size-rem(50px, true);
    height: size-rem(50px, true);
    background-color: $clr-medium-grey;
    margin-right: size-rem(10px, true);
  }
}
