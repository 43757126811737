@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $clr-white;
  padding: size-rem(26px) size-rem(40px);
  position: relative;
  border: 1px solid $clr-grey;
  border-radius: size-rem(40px);
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  text-transform: uppercase;
  color: $clr-dark-black;
  cursor: pointer;
  transition: all 0.5s ease;

  &:active {
    background-color: $clr-dark-grey;
    border: 1px solid $clr-dark-grey;
    color: $clr-white;
  }

  @include vp--740 {
    border: size-rem(0.675676px, true) solid $clr-grey;
    padding: size-rem(26px, true) size-rem(40px, true);
    font-size: size-rem(16px, true);
    border-radius: size-rem(100px, true);
  }
}
