@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.bulkBox {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.bulkBoxMob {
  display: flex;
  height: 100%;
  justify-content: center;
  object-fit: cover;
  position: relative;

  overflow-x: scroll;
  overflow-y: hidden;
}

.scrollSnap {
  scroll-snap-type: x mandatory;
}

.tooltipWrapper {
  aspect-ratio: 1216/810;
  z-index: 10;
}

.tooltipWrapperInner {
  width: 100%;
  height: 100%;
  position: relative;
}

.tooltipWrapper,
.image,
.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
  object-fit: cover;
  scroll-snap-align: center;

  @include vp--740 {
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    padding-right: 0;
  }
}

.svg {
  z-index: 11;
}

.svgBox {
  z-index: 11;
}

.active {
  transition: all ease-in-out 0.3s;
  path:nth-child(1) {
    opacity: 0.5;
    transition: all ease-in-out 0.3s;
  }
}

.hover:hover {
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  path:nth-child(1) {
    opacity: 0.3;
    transition: all ease-in-out 0.3s;
  }
  g:nth-child(3) {
    opacity: 1;
    transition: all ease-in-out 0.3s;
  }
}

.notAvailableHover:hover {
  transition: all ease-in-out 0.3s;

  path:nth-child(1) {
    opacity: 0.3;
    transition: all ease-in-out 0.3s;
  }
}

.innerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.gradient {
  width: 100%;
  height: size-rem(178px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));

  @include vp--740 {
    z-index: 1;
    position: fixed;
    height: size-rem(214px, true);
  }
}
