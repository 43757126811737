@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.nl_preloader {
  position: absolute;
  top: 45%;
  left: 48%;
  pointer-events: none;
  @include vp--740 {
    top: 42%;
    left: 45%;
  }
}

.nl_reploader_father {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 9999999;
  pointer-events: none;

  &.__hide {
    opacity: 0;
  }
}

.loader {
  width: size-rem(60px);
  height: size-rem(60px);
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid $clr-grey;
  border-bottom: 5px solid transparent;
  border-right: 5px solid $clr-grey;
  animation: rotation 1s linear infinite;
  @include vp--740 {
    width: size-rem(60px, true);
    height: size-rem(60px, true);
    border-top: 5px solid $clr-grey;
    border-bottom: 5px solid transparent;
    border-right: 5px solid $clr-grey;
  }
}

.loaderApple {
  width: size-rem(60px);
  height: size-rem(60px);
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid $clr-grey;
  border-right: 5px solid $clr-grey;
  animation: rotation 1s linear infinite;
  @include vp--740 {
    width: size-rem(60px, true);
    height: size-rem(60px, true);
    border-top: 5px solid $clr-grey;
    border-right: 5px solid $clr-grey;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
