@font-face {
  font-family: 'GT Pressura Mono';
  src: url('../fonts/GT-Pressura-Pro-Mono-Trial-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

@font-face {
  font-family: 'GT Pressura LC Mono Regular';
  src:
    url('../fonts/GTPressuraLCMono-Regular.woff2') format('woff2'),
    url('../fonts/GTPressuraLCMono-Regular.woff') format('woff'),
    url('../fonts/GTPressuraLCMono-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

@font-face {
  font-family: 'GT Pressura Pro Trial';
  src:
    url('../fonts/GT-Pressura-Pro-Trial-Light.woff2') format('woff2'),
    url('../fonts/GT-Pressura-Pro-Trial-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: 'GT Pressura Pro Trial Regular';
  src: url('../fonts/GT-Pressura-Pro-Trial-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

@font-face {
  font-family: 'Pressura Standart Text';
  src:
    url('../fonts/GTPressuraLC-Text.woff2') format('woff2'),
    url('../fonts/GT-Pressura-LC-Standard-Text.otf') format('opentype'),
    url('../fonts/GT-Pressura-LC-Standard-Text.woff') format('woff'),
    url('../fonts/GT-Pressura-LC-Text.ttf') format('ttf');
  font-style: normal;
  font-weight: 350;
  font-display: auto;
}

@font-face {
  font-family: 'Pressura Mono Regular';
  src:
    url('../fonts/GT-Pressura-LC-Mono-Regular.woff2') format('woff2'),
    url('../fonts/GT-Pressura-LC-Mono-Regular.otf') format('opentype'),
    url('../fonts/GT-Pressura-LC-Mono-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}
