@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  padding: size-rem(50px) 5% 0;

  @include vp--740 {
    padding: 0;
  }
}

.views {
  display: flex;
  gap: size-rem(15px, true);
}

.view {
  padding: size-rem(10px, true) size-rem(16px, true);
  border-radius: size-rem(50px, true);
  border: 1px solid transparent;
  background-color: transparent;
  font-family: $main-font-family;
  font-size: size-rem(12px, true);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  color: $clr-dark-black;

  &__active {
    padding: size-rem(10px, true) size-rem(16px, true);
    border-radius: size-rem(50px, true);
    border: 1px solid $clr-grey;
    background-color: transparent;
    font-family: $main-font-family;
    font-size: size-rem(12px, true);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    color: $clr-dark-black;
  }
}

.result {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include vp--740 {
    margin-top: size-rem(20px, true);
    padding: 0 5%;
  }
}

.amount {
  font-family: $main-font-family;
  font-size: size-rem(16px, true);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  color: $clr-lightdark-grey;
}

.places {
  display: flex;
  flex-direction: column;
  gap: size-rem(20px, true);
  margin-top: size-rem(20px, true);

  padding: 0 5%;
}
