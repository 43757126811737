:root {
  --animate-duration: 400ms;
  --animate-easing: cubic-bezier(0.23, 0.44, 0.29, 0.9);
  --bg-color: #131212;
}

.imgContainer {
  --x: 0;
  --y: 0;
  --d: 50;
  --s: 1;

  &:hover {
    --d: 20;
    transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    transition: transform var(--animate-duration) var(--animate-easing);
    img {
      transform: scale(var(--s)) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }
  }
}

.imgContainer img {
  max-width: 100%;
  min-width: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: transform var(--animate-duration) var(--animate-easing);
}
