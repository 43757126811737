@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.tooltipBox {
  position: relative;
  display: inline-block;
}

.wrapper {
  position: absolute;
}

.tooltipContent {
  position: relative;
  height: 100%;
  width: auto;
}

.bottomSquare {
  width: size-rem(16px);
  height: size-rem(16px);
  background-color: $clr-green;
  position: absolute;
  z-index: 4;
  bottom: size-rem(-6px);
  left: size-rem(16px);
  transform: rotate(45deg);

  @include vp--740 {
    width: size-rem(16px, true);
    height: size-rem(16px, true);
    bottom: size-rem(54px, true);
    left: size-rem(16px, true);
  }
}
.topSquare {
  width: size-rem(16px);
  height: size-rem(16px);
  background-color: $clr-medium-grey;
  position: absolute;
  z-index: 22;
  bottom: size-rem(-4px);
  left: size-rem(16px);
  transform: rotate(45deg);

  @include vp--740 {
    width: size-rem(16px, true);
    height: size-rem(16px, true);
    bottom: size-rem(56px, true);
    left: size-rem(16px, true);
  }
}

.tooltip {
  position: relative;
  left: 0;
  background: $clr-medium-grey;
  border: 1px solid $clr-green;
  color: $clr-black;
  width: max-content;
  padding: size-rem(16px);
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(14px);
  z-index: 12 !important;

  & > span {
    position: relative !important;
    z-index: 100;
  }

  @include vp--740 {
    top: size-rem(-60px, true);
    padding: size-rem(16px, true);
    font-size: size-rem(12px, true);
  }
}
