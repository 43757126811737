@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/functions.scss';
@import '../../../../assets/scss/mixins/flexbox.scss';
@import '../../../../assets/scss/mixins/responsive.scss';
@import '../../../../assets/scss/mixins/custom-scrollbar.scss';

.nextButton {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(16px);
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;

  background-color: $clr-green;
  padding: size-rem(16px) size-rem(28px);
  border-radius: size-rem(100px);
  transition: all 0.13s ease;
  margin-left: size-rem(12px);

  &:hover {
    background-color: $clr-black;
  }

  @include vp--740 {
    padding: size-rem(16px, true) size-rem(28px, true);
    font-size: size-rem(16px, true);
    position: fixed;
    bottom: size-rem(32px, true);
    right: size-rem(20px, true);

    z-index: 100;

    &:hover {
      background-color: $clr-green;
    }
  }
}
