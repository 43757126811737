@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr size-rem(520px);
  grid-template-rows: 1fr;
  position: relative;
  height: 100vh;
  width: 100%;
}

.rightBlock {
  position: relative;
  background-color: $clr-medium-grey;
  height: 100%;
  width: size-rem(520px);

  &__tabs {
    position: relative;
    margin-top: size-rem(120px);
    margin-left: size-rem(45px);
    z-index: 9;
  }
}

.title {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(50px);
  line-height: 110%;
  letter-spacing: -0.02em;
  color: $clr-black;
  margin-top: size-rem(32px);
  margin-left: size-rem(60px);
}

.description {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(32px);
  line-height: 110%;
  color: $clr-black;

  margin-top: size-rem(20px);
  margin-left: size-rem(60px);

  &__lock {
    color: $clr-dark-grey;
    padding-right: size-rem(60px);
  }
}

.gradient {
  width: 100%;
  width: size-rem(500px);
  height: size-rem(120px);
  position: fixed;
  top: 0;
  right: 0;
  background: linear-gradient($clr-medium-grey, transparent);
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 10;
}

.infoWideScreen {
  overflow-x: hidden;
  overflow-y: auto;
  @include custom-scrollbar();
}
.bottomLinksWideScreen {
  top: size-rem(660px);
  bottom: auto;
}
