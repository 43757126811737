@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.blurTooltip {
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: size-rem(50px);
  border: 0.5px solid rgba(255, 255, 255, 0.5);

  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(6px);

  color: $clr-white;
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 350;
  line-height: 140%;

  @include vp--740 {
    backdrop-filter: blur(6px, true);
    font-size: size-rem(14px, true);
    border-radius: size-rem(50px, true);
  }
}
