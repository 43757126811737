@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/functions.scss';
@import '../../../../assets/scss/mixins/flexbox.scss';
@import '../../../../assets/scss/mixins/responsive.scss';
@import '../../../../assets/scss/mixins/custom-scrollbar.scss';

.listLink {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $clr-black;
}
