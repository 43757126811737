@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  background-color: $clr-wild-sand;
  width: 100%;
  padding: 0 5%;
  padding-bottom: size-rem(70px);
}

.title {
  color: $clr-black;
  font-family: $main-font-family;
  font-size: size-rem(40px);
  font-style: normal;
  font-weight: 350;
  line-height: 110%;
  letter-spacing: size-rem(-0.8px);
  padding-top: size-rem(100px);
}

.filters {
  margin-top: size-rem(51px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parkingTypes {
  display: flex;
  gap: size-rem(16px);
}

.parkingTypeButton {
  padding: size-rem(20px) size-rem(32px);
  background-color: transparent;
  border: 1px solid $clr-grey-2;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.13s ease;
  height: size-rem(65px);
  font-size: size-rem(18px);
  font-family: $main-font-family;
  color: $clr-black;

  &__active {
    padding: size-rem(20px) size-rem(32px);
    background-color: $clr-green;
    border: 1px solid $clr-green;
    border-radius: 50px;
    color: $clr-white;
    height: size-rem(65px);
    font-size: size-rem(18px);
    font-family: $main-font-family;
  }

  &:hover {
    border: 1px solid $clr-lightdark-grey;
    transition: all 0.13s ease;
  }
}

.slider {
  width: size-rem(322px);
  height: size-rem(73px);
  margin-bottom: size-rem(10px);
  margin-right: size-rem(120px);
}

.note {
  font-family: $main-font-family;
  font-size: size-rem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: size-rem(-0.28px);
  color: $clr-lightdark-grey;
}

.range {
  display: flex;
  justify-content: space-between;
  padding: size-rem(13px) 0 size-rem(19px);

  & span {
    color: $clr-black;
    font-family: $main-font-family;
    font-size: size-rem(16px);
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    padding-left: size-rem(6px);
  }

  & div {
    font-family: $main-font-family;
    font-size: size-rem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: size-rem(-0.28px);
    color: $clr-dark-grey;
  }
}

.buttonBox {
  width: size-rem(300px);
  display: flex;
  justify-content: end;
}

.searchButton {
  padding: size-rem(26px) size-rem(40px);
  background-color: $clr-black;
  color: $clr-white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  text-transform: uppercase;
  transition: all 0.13s ease-in-out;

  &:hover {
    background-color: $clr-darker-grey;
    transition: all 0.13s ease-in-out;
  }

  &:active {
    background-color: $clr-middle-grey;
    transition: all 0.13s ease-in-out;
  }
}

.resetButton {
  display: flex;
  align-items: center;
  gap: size-rem(6px);
  margin-top: size-rem(90px);
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-in-out;
  color: $clr-black;

  & span {
    font-family: $main-font-family;
    font-size: size-rem(18px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: size-rem(-0.36px);
    transition: all 0.13s ease-in-out;
  }

  &:hover {
    & svg {
      transform: rotate(360deg);
      transition: all 0.3s ease-in-out;
    }

    & span {
      opacity: 0.65;
      transition: all 0.13s ease-in-out;
    }
  }
}

.filtersButtonMob {
  padding: size-rem(26px) size-rem(40px);
  background-color: $clr-black;
  color: $clr-white;
  border: none;
  border-radius: size-rem(50px);
  cursor: pointer;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-style: normal;
  font-weight: 350;
  line-height: 120%;
  text-transform: uppercase;
  transition: all 0.13s ease-in-out;

  @include vp--740 {
    padding: size-rem(16px, true) size-rem(28px, true);
    font-size: size-rem(16px, true);
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    text-transform: none;
    border-radius: size-rem(50px, true);
    width: calc(100% - size-rem(58px, true));
  }
}

.wrapperMob {
  // background-color: $clr-wild-sand;
  width: 100%;
  padding: 0 5%;
  padding-bottom: size-rem(70px);
  display: flex;
  padding-top: size-rem(30px, true);
}

.changeFilters {
  background-color: $clr-black;
  border-radius: 50%;
  border: none;
  width: size-rem(50px, true);
  height: size-rem(50px, true);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: size-rem(8px, true);
}
