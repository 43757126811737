@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.active {
  opacity: 1;
}

.compass {
  height: size-rem(84px);
  height: size-rem(100px);

  @include vp--740 {
    height: size-rem(84px, true);
  }
}

.compassGenplanMob {
  @include vp--740 {
    width: size-rem(77px, true);
    height: size-rem(74px, true);
    background: rgba($clr-white, 0.8);
    padding: size-rem(10px, true) size-rem(13px, true) size-rem(15px, true) size-rem(15px, true);
    border-radius: size-rem(20px, true);
    margin-top: size-rem(4px, true);

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
