@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.dropitems {
  position: relative;
  z-index: 99999999;
  background: $clr-light-grey;

  @include vp--740 {
    width: 100%;
    padding-bottom: size-rem(30px, true);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    z-index: 999999999;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    backdrop-filter: blur(2px);
    background: #1c1c1c70;
  }

  &__title {
    @include vp--740 {
      padding: size-rem(20px, true) 0 size-rem(20px, true) size-rem(24px, true);
      font-size: size-rem(20px, true);
      font-family: $main-font-family;
      font-style: normal;
      font-weight: 350;
    }
  }

  &__item {
    padding: size-rem(22px) 0 size-rem(22px) size-rem(24px);
    font-size: size-rem(16px);
    color: $clr-dark-black;
    line-height: 120%;
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    position: relative;

    @include vp--740 {
      padding: size-rem(20px, true) 0 size-rem(20px, true) size-rem(24px, true);
      font-size: size-rem(16px, true);
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border: none;
      }

      &::after {
        position: absolute;
        content: '';
        width: size-rem(6px, true);
        height: size-rem(6px, true);
        border: 1px solid $clr-grey;
        border-radius: 50%;
        right: size-rem(27px, true);
        top: 50%;
        transform: translate(50% 50%);
        background: transparent;

        @include vp--740 {
          width: size-rem(14px, true);
          height: size-rem(14px, true);
          top: 40%;
        }
      }
    }

    &:hover {
      background: $clr-green;
      color: $clr-white;

      &::after {
        position: absolute;
        content: '';
        width: size-rem(6px);
        height: size-rem(6px);
        border-radius: 50%;
        right: size-rem(27px);
        top: 50%;
        transform: translate(50% 50%);
        background: $clr-white;

        @include vp--740 {
          top: 40%;
          width: size-rem(14px, true);
          height: size-rem(14px, true);
          right: size-rem(27px, true);
        }
      }
    }
  }

  &__active {
    &::after {
      position: absolute;
      content: '';
      width: size-rem(6px, true);
      height: size-rem(6px, true);
      border-radius: 50%;
      right: size-rem(27px, true);
      top: 50%;
      transform: translate(50% 50%);
      background: $clr-green;

      @include vp--740 {
        width: size-rem(14px, true);
        height: size-rem(14px, true);
        top: 40%;
      }
    }
  }
}
